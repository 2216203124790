<template>
  <div class="main">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <common-top
      title="修改部门"
      @search="searchUserStatus = true"
      :isShowSearch="isUserPm"
    ></common-top>

    <Modal v-model="editModal" width="200">
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>部门确认</span>
      </p>
      <div style="text-align: left; font-weight: bold">
        <p style="color: #515a6e">姓名: {{ wxUser.name }}</p>
        <p style="color: #515a6e">
          目标部门:<span style="color: green">
            {{ curDept.title }}
          </span>
        </p>
      </div>
      <div slot="footer">
        <Button type="info" size="large" long @click="editDept"
          >确认修改</Button
        >
      </div>
    </Modal>

    <dept-tree
      :showModal="showModal"
      @chooseDept="chooseDept"
      @cancel="showModal = false"
    ></dept-tree>

    <loading-cmp v-if="showLoad"></loading-cmp>
    <div class="scrollDiv" v-show="isUserPm" style="margin-top: 10px">
      <div v-show="searchUserStatus">
        <user-list @searchBack="searchBack" @chooseItem="chooseItem">
        </user-list>
      </div>
      <div v-show="!searchUserStatus">
        <div class="formItem">
          <div class="formItemTitle">姓名</div>
          <div class="detail">
            <Input
              width="200px"
              type="text"
              disabled
              v-model="wxUser.name"
              placeholder="姓名"
            >
            </Input>
          </div>
        </div>

        <div v-for="(deptItem, deptIndex) in wxUser.deptList" :key="deptIndex">
          <div class="formItem">
            <div class="formItemTitle">
              单位
              <span v-if="wxUser.deptList && wxUser.deptList.length > 1">{{
                deptIndex + 1
              }}</span>
            </div>
            <div
              class="detail"
              style="color: #808695; font-weight: bold; text-align: left"
            >
              {{ deptItem.dept_2nd_name }} / {{ deptItem.dept_3nd_name }}
            </div>
          </div>
        </div>

        <div class="formItem" style="height: auto">
          <div class="formItemTitle">选择单位</div>
          <div
            class="detail"
            style="color: #808695; font-weight: bold; text-align: left"
          >
            {{ curDept.title }}
          </div>
          <div
            style="position: absolute; right: 20px"
            @click="showModal = true"
          >
            <Button type="default">选择</Button>
          </div>
        </div>

        <div style="padding: 10px 20px" @click="editUserCofirm">
          <div class="buttonLarge">修改部门</div>
        </div>
      </div>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import { wxConPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import deptTree from "../../components/deptTree.vue";

import userList from "../../components/userList.vue";

export default {
  name: "changeDept",
  components: {
    loadingCmp,
    noPassCom,
    deptTree,
    diaLogliu,
    userList,
    commonTop,
  },

  data() {
    return {
      editModal: false, // 显示提示框

      isUserPm: false,
      showLoad: true, // 显示加载框

      showModal: false, // 显示部门弹框
      showCode: false,

      searchVal: "",
      mobile: "", // 输入用户基本信息
      wxUser: {},
      curDept: {},
      searchUserStatus: true, // 显示用户列表 | 显示编辑用户
      toSearchData: false, // 用户组件是否查询

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {
    chooseDept(emitData) {
      this.curDept = emitData;
      this.showModal = false;
    },
    editUserCofirm() {
      if (!this.wxUser.userid) {
        this.$Message.info("请重新选择用户");
        return;
      }
      if (!this.curDept.id) {
        this.$Message.info("请选择部门");
        return;
      }
      this.editModal = true;
    },
    // 搜索用户组件框方法
    searchBack() {
      this.$router.replace("/statsmain");
    },
    chooseItem(wxUser) {
      this.wxUser = wxUser;
      this.searchUserStatus = false;
    },

    back() {
      this.$router.push({ path: "/statsmain" });
    },
    regMobile(str) {
      return str.match(/^(0|86|17951)?1[0-9]{10}$/);
    },
    async editDept() {
      this.editModal = false;
      if (!this.wxUser.userid) {
        this.$Message.info("请重新选择用户");
        return;
      }

      const query = {
        userid: this.wxUser.userid,
        name: this.wxUser.name,
        deptId: this.curDept.id,
      };
      console.log("----", query);

      try {
        this.showLoad = true;
        const result = await wxConPost(
          "/services/gttt-user-mgt/user/updateBaseDeptInfo",
          query
        );

        if (!result.err) {
          this.$Message.info("部门修改成功");
          this.curDept = {};
        } else {
          this.$Notice.warning({
            title: result.err,
          });
        }
        // this.searchUserStatus = true;
        this.showLoad = false;
      } catch (e) {
        console.log("=----", e);
        this.$Notice.warning({
          title: "修改失败，请稍后重试",
        });
        this.showLoad = false;
      }
    },
  },
  async created() {
    const userPm = getUserPm("通讯录管理");
    const _curUserZdTxlPm = _.get(userPm, ["pmValue", "txl"], []);
    if ((userPm && !_.isEmpty(_curUserZdTxlPm)) || userPm.pmSuper) {
      this.isUserPm = true;
    } else {
      this.isUserPm = false;
    }
    this.showLoad = false;
  },
  watch: {},
};
</script>

<style scoped>
.formItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  line-height: 41px;
  position: relative;
}
.formItem .formItemTitle {
  font-weight: bold;
  color: #2d4761;
}
.formItem .detail {
  width: 80%;
}
</style>
